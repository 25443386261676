export const config = {
    RPC_URL: 'https://rpc-persistent-ia.cosmosia.notional.ventures',
    REST_URL: 'https://api-persistent-ia.cosmosia.notional.ventures',
    EXPLORER_URL: 'https://www.mintscan.io/persistence',
    STAKING_URL: 'https://persistence.omniflix.co/stake',
    NETWORK_NAME: 'Persistence',
    NETWORK_TYPE: 'mainnet',
    CHAIN_ID: 'core-1',
    CHAIN_NAME: 'Persistence',
    COIN_DENOM: 'XPRT',
    COIN_MINIMAL_DENOM: 'uxprt',
    COIN_DECIMALS: 6,
    PREFIX: 'persistence',
    COIN_TYPE: 118,
    COSMOSTAION: 'persistence',
    COINGECKO_ID: 'persistence',
    GAS_PRICE_STEP_LOW: 0,
    GAS_PRICE_STEP_AVERAGE: 0.025,
    GAS_PRICE_STEP_HIGH: 0.04,
    FEATURES: ['ibc-transfer', 'ibc-go'],
};
